.App {
  text-align: center;
}
.slick-prev:before,
.slick-next:before {
  color: #b30018 !important;
  font-size: 30px !important;
}
#main-slider .slick-current {
  transform: scale(1.6);
  height: 22rem;
  width: 22rem;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 250ms;
  transition-delay: 200ms;
}
.slick-track {
  height: 30rem;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 768px) {
  .paginationBttns {
    width: 80%;
    height: 40px;
    list-style: none;
    margin: auto;
    display: flex;
    justify-content: center;
  }

  .paginationBttns a {
    padding: 10px;
    display: flex;
    margin: 8px;
    border-radius: 5px;
    border: 1px solid #0f1929;
    color: #0f1929;
    cursor: pointer;
  }

  .paginationBttns a:hover {
    color: white;
    background-color: #f00020;
  }

  .paginationActive a {
    color: white;
    background-color: #0f1929;
  }

  /* .paginationDisabled a {
    color: grey;
    background-color: grey;
  } */
}

@media (max-width: 425px) {
  .paginationBttns {
    width: 10%;
    height: 10px;
    list-style: none;
    display: flex;
    margin: auto;
    justify-content: center;
  }

  .paginationBttns a {
    padding: 10px;
    display: none;
    margin: 8px;
    border-radius: 5px;
    border: 1px solid #0f1929;
    color: #0f1929;
    cursor: pointer;
  }

  .paginationBttns a:hover {
    color: white;
    background-color: #ff2b2b;
  }

  .paginationActive a {
    color: white;
    background-color: #0f1929;
    display: block;
  }

  .next a {
    display: block;
  }
  .previous a {
    display: block;
  }
}
